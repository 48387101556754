<template>

  <header class="site__header" :class="{active: isShowHeader}" id="header">

    <div class="site__centered">

      <a href="#" class="site__header-btn" :class="{opened: isShowMenu}" ref="menuButton"><span></span></a>

      <router-link to="/" class="logo">
        <img src="../assets/new/img/logo.svg" alt="distribune.com">
      </router-link>

      <div class="site__header-wrap" :class="{opened: isShowMenu}">

        <nav class="menu">

          <div class="menu__wrap menu__wrap_1">
            <router-link to="/" class="menu__item">{{ $t('menu.home') }}</router-link>
            <router-link :to="links.webmasters" class="menu__item">{{ $t('menu.publishers') }}</router-link>
            <router-link :to="links.contentOwners" class="menu__item">{{ $t('menu.contentOwners') }}</router-link>
          </div><!-- /menu__wrap -->

          <div class="menu__wrap">
            <a :href="links.registration" class="menu__item menu__item_2" target="_blank">
              {{ $t('menu.signUp') }}
            </a>
            <a :href="links.login" class="menu__item menu__item_2" target="_blank">
              {{ $t('menu.login') }}
            </a>
          </div><!-- /menu__wrap -->

        </nav><!-- /menu -->

      </div><!-- /site__header-wrap -->

    </div><!-- /site__centered -->

    <a href="#" class="switch-lang" @click="switchLang">

            <span class="switch-lang__wrap" v-if="currentLanguage === 'ru'">
                <img src="../assets/new/img/switch-lang/switch-lang__en.png" alt=""> <span>en</span>
            </span><!-- /switch-lang__wrap -->

      <span class="switch-lang__wrap" v-if="currentLanguage === 'en'">
                <img src="../assets/new/img/switch-lang/switch-lang__ru.png" alt=""> <span>ru</span>
            </span><!-- /switch-lang__wrap -->

    </a><!-- /switch-lang -->

  </header><!-- /site__header -->

</template>

<script>
export default {
  name: "TheHeader",
  data() {
    return {
      isShowMenu: false,
      currentLanguage: this.$root.$i18n.locale,
      isShowHeader: true
    }
  },
  mounted() {
    let self = this,
        menuBtn = this.$refs.menuButton,
        prevScrollY = null;
    window.addEventListener('resize', function () {
      self.isShowMenu = false;
    });
    window.addEventListener('scroll', function () {
      const curScrollY = window.scrollY;
      if (curScrollY < 50) {
        self.isShowHeader = true;
        return false;
      }
      if (prevScrollY !== null) {
        if (prevScrollY > curScrollY) {
          self.isShowHeader = true;
        } else if (prevScrollY < curScrollY) {
          self.isShowHeader = false;
        }
      }
      prevScrollY = curScrollY;
    });
    document.addEventListener('click', function () {
      self.isShowMenu = false;
    });
    menuBtn.addEventListener('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      self.isShowMenu = !self.isShowMenu;
      return false;
    });
  },
  methods: {
    switchLang(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$root.$i18n.locale = this.$root.$i18n.locale === 'ru' ? 'en' : 'ru';
      this.currentLanguage = this.$root.$i18n.locale;
      localStorage.setItem('curLang', this.currentLanguage);

      this.$emit('clicked', this.currentLanguage);
    }
  },
  computed: {
    links() {
      return this.$store.state.static.routePaths;
    }
  }
}
</script>
