import { render, staticRenderFns } from "./BloggersScreen.vue?vue&type=template&id=13294864&scoped=true&"
import script from "./BloggersScreen.vue?vue&type=script&lang=js&"
export * from "./BloggersScreen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13294864",
  null
  
)

export default component.exports