<template>
    <div class="contact">
        <h3 class="block__title block__title-contact">Остались вопросы?</h3>
        <h6 class="block__subtitle">Задайте их нам и мы ответим в ближайшее время.</h6>
        <div class="cont__form">
            <form class="form"  @submit.prevent="onSubmit">
                <div class="form__left">
                    <input type="text" class="form__input" name="Name" id="username" placeholder="Ваше имя" required v-model="contactFormData.Name">
                    <input type="email" class="form__input" name="Email" id="useremail" placeholder="Ваш email" required v-model="contactFormData.Email">
                    <select name="Select" id="cont" class="form__input form__select"  v-model="contactFormData.Select" required>
                        <option class="form__option form__option-disabled" value="" disabled selected>Выберите (блогер или вебмастер)</option>
                        <option class="form__option" value="bloger">Блогер</option>
                        <option class="form__option" value="webmaster">Вебмастер</option>
                    </select>
                </div>
                <textarea v-model="contactFormData.Message" name="Message" id="message" cols="25" rows="10" class="form__input form__textarea" placeholder="Ваш вопрос" required></textarea>
                <button type="submit" class="btn form-btn">Отправить</button>
            </form>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: "ContactForm",
        data () {
            return {
                contactFormData: {
                    project_name: 'Distribune.com',
                    admin_email: this.$store.state.static.mail_to_contact_form,
                    form_subject: 'Вопрос от пользователя Distribune',
                    Name: '',
                    Email: '',
                    Select: '',
                    Message: '',
                }
            }
        },
        methods: {
            onSubmit(){
                this.axios({
                    method: 'POST',
                    timeout: 1000,
                    url: `/mail.php`,
                    data: this.contactFormData,
                    headers: {'Content-Type': 'application/form-data'},
                    transformRequest: [function (data) {
                        let fd = new FormData();
                        _.forIn(data, (value, key) => {
                            fd.append(key, value)
                        });
                        return fd;
                    }],
                })
                .then(() => {
                    this.resetFormData();
                    alert('Заявка отправлена!')
                })
                .catch(() => {
                    this.resetFormData();
                    alert('Что-то пошло не так!')
                })
            },
            resetFormData(){
                this.contactFormData =  {
                    project_name: 'Distribune.com',
                        admin_email: 'mishaakulenko@gmail.com',
                        form_subject: 'Вопрос от пользователя Distribune',
                        Name: '',
                        Email: '',
                        Select: '',
                        Message: '',
                }
            }
        }
    }
</script>

<style scoped>

</style>
