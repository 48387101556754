<template>

  <div>

    <footer class="site__footer" ref="footer">

      <div class="site__centered">

        <div class="site__footer-logo">

          <router-link to="/" class="logo">
            <img src="../assets/new/img/logo.svg" alt="distribune.com" @load="calculateFooterHeight">
          </router-link>

        </div><!-- /site__footer-logo -->

        <div class="footer-menu">
          <router-link to="/" class="footer-menu__item">{{ $t('menu.home') }}</router-link>
          <router-link :to="links.webmasters" class="footer-menu__item">{{ $t('menu.publishers') }}</router-link>
          <router-link :to="links.contentOwners" class="footer-menu__item">{{ $t('menu.contentOwners') }}</router-link>
          <a :href="links.registration" class="footer-menu__item" target="_blank">
            {{ $t('menu.signUp') }}
          </a>
          <a :href="links.login" class="footer-menu__item" target="_blank">
            {{ $t('menu.login') }}
          </a>
        </div><!-- /footer-menu -->

        <div class="footer-info">

          <div class="footer-info__item">
            <router-link :to="links.privacyPolicy">{{ $t('footer.pcp') }}</router-link>
          </div><!-- /footer-info__item -->

          <div class="footer-info__item">
            <router-link :to="links.agreement">{{ $t('footer.pa') }}</router-link>
          </div><!-- /footer-info__item -->

          <div class="footer-info__item">
            <a href="mailto:support@distribune.com">support@distribune.com</a>
            <br>
            Data Technologies Services LTD
          </div><!-- /footer-info__item -->

        </div><!-- /footer-info -->

        <social-icons></social-icons>

        <div class="copyright">
          {{ $t('footer.ars') }} © {{ new Date().getFullYear() }}
        </div><!-- /copyright -->

      </div><!-- /site__centered -->

    </footer><!-- /site__footer -->

    <div class="site__increase" :style="{height: footerHeight + 'px'}"></div><!-- /site__increase -->

  </div>

</template>

<script>
import socialIcons from './socialIcons';

export default {
  name: "TheFooter",
  components: {
    socialIcons
  },
  data: function () {
    return {
      footerHeight: 0
    }
  },
  updated: function () {
    this.calculateFooterHeight();
  },
  computed: {
    links() {
      return this.$store.state.static.routePaths
    }
  },
  methods: {
    calculateFooterHeight() {
      if (this.$refs.footer) {
        this.footerHeight = this.$refs.footer.clientHeight;
        this.$emit('get-footer-height', {
          footerHeight: this.footerHeight
        })
      }
    }
  },
  mounted() {
    let self = this;
    this.calculateFooterHeight();
    window.addEventListener('resize', function () {
      self.calculateFooterHeight();
    });
  }
}
</script>

<style scoped>

</style>
