<template>
    <div>
        <Header></Header>
        <section class="intro intro-bloggers" id="intro">
            <div class="container">
                <div class="intro__inner">
                    <h1 class="intro__title">Distribune.com</h1>
                    <p class="intro__text">
                        Уникальная платформа для популяризации и дополнительной монетизации вашего видеоконтента
                    </p>
                    <div class="intro__mouse">
                        <img :src="`${staticDomain}/img/mouse.png`" alt="" class="mouse">
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-bloggers">
            <div class="container">
                <!-- Как это работает -->
                <div class="how-it-works">
                    <h3 class="block__title">Как это работает</h3>
                    <div class="hiw__inner hiw__inner-four">
                        <div class="hiw__item">
                            <div class="hiw__pic">
                                <img :src="`${staticDomain}/img/why-1.png`" alt="">
                                <span>1</span>
                            </div>
                            <div class="hiw__text">
                                Вы предоставляете нам видеоконтент
                            </div>
                        </div>

                        <div class="hiw__item">
                            <div class="hiw__pic">
                                <img :src="`${staticDomain}/img/why-4.png`" alt="">
                                <span>2</span>
                            </div>
                            <div class="hiw__text">
                                Мы встраиваем его в свой плеер
                            </div>
                        </div>

                        <div class="hiw__item">
                            <div class="hiw__pic">
                                <img :src="`${staticDomain}/img/why-6.png`" alt="">
                                <span>3</span>
                            </div>
                            <div class="hiw__text">
                                Тысячи наших сайтов партнёров публикуют видео у себя на сайте
                            </div>
                        </div>

                        <div class="hiw__item">
                            <div class="hiw__pic">
                                <img :src="`${staticDomain}/img/why-7.png`" alt="">
                                <span>4</span>
                            </div>
                            <div class="hiw__text">
                                Вы получаете дополнительные просмотры и новых подписчиков
                            </div>
                        </div>
                    </div>
                </div><!-- Конец Как это работает -->
                <!-- Преимущества -->
                <div class="advantages">
                    <h3 class="block__title">Преимущества работы с нами</h3>
                    <div class="adv__inner">
                        <div class="adv__item">
                            <div class="adv__pic">
                                <img :src="`${staticDomain}/img/bloggers/preim-1.png`" alt="">
                            </div>
                            <div class="preim__text">
                                Дополнительные <br> просмотры
                            </div>
                        </div>
                        <div class="adv__item">
                            <div class="adv__pic">
                                <img :src="`${staticDomain}/img/bloggers/preim-2.png`" alt="">
                            </div>
                            <div class="preim__text">
                                Прирост подписчиков на ютюб
                            </div>
                        </div>
                        <div class="adv__item">
                            <div class="adv__pic">
                                <img :src="`${staticDomain}/img/bloggers/preim-3.png`" alt="">
                            </div>
                            <div class="preim__text">
                                Рост цен на рекламные интеграции
                            </div>
                        </div>
                        <div class="adv__item">
                            <div class="adv__pic">
                                <img :src="`${staticDomain}/img/bloggers/preim-4.png`" alt="">
                            </div>
                            <div class="preim__text">
                                Дополнительная монети-<br>зация вашего контента
                            </div>
                        </div>
                        <div class="adv__item">
                            <div class="adv__pic">
                                <img :src="`${staticDomain}/img/bloggers/preim-5.png`" alt="">
                            </div>
                            <div class="preim__text">
                                Мы предоставляем аналитику по показам ваших видео у нас в сервисе
                            </div>
                        </div>
                    </div>
                    <div class="adv__outer">
                        В нашем плеере предусмотрена иконка со ссылкой перехода на <span class="orange">ваш youtube</span> канал!
                        <img :src="`${staticDomain}/img/bloggers/adv-yt.png`" alt="">
                    </div>
                </div>
                <!-- Кейсы -->
                <div class="cases">
                    <div class="block__title">Кейсы</div>
                    <div class="cases__inner">
                        <div class="cases__item">
                            <img :src="`${staticDomain}/img/bloggers/cases-yt.png`" alt="" class="cases__imgtop">
                            <div class="case__info">
                                <div class="case__info-item">
                                    <span class="info-item__point">Дополнительные просмотры видео:</span>
                                    <p class="info-item__number">3 213 123</p>
                                </div>
                                <div class="case__info-item">
                                    <span class="info-item__point">Дополнительные просмотры видео:</span>
                                    <p class="info-item__number">+3%</p>
                                </div>

                                <div class="case__info-item">
                                    <span class="info-item__point">Стоимость интеграции:</span>
                                    <p class="info-item__number">+3%</p>
                                </div>
                            </div>
                        </div>
                        <div class="cases__item">
                            <img :src="`${staticDomain}/img/bloggers/cases-yt.png`" alt="" class="cases__imgtop">
                            <div class="case__info">
                                <div class="case__info-item">
                                    <span class="info-item__point">Дополнительные просмотры видео:</span>
                                    <p class="info-item__number">3 213 123</p>
                                </div>
                                <div class="case__info-item">
                                    <span class="info-item__point">Дополнительные просмотры видео:</span>
                                    <p class="info-item__number">+3%</p>
                                </div>

                                <div class="case__info-item">
                                    <span class="info-item__point">Стоимость интеграции:</span>
                                    <p class="info-item__number">+3%</p>
                                </div>
                            </div>
                        </div>
                        <div class="cases__item">
                            <img :src="`${staticDomain}/img/bloggers/cases-yt.png`" alt="" class="cases__imgtop">
                            <div class="case__info">
                                <div class="case__info-item">
                                    <span class="info-item__point">Дополнительные просмотры видео:</span>
                                    <p class="info-item__number">3 213 123</p>
                                </div>
                                <div class="case__info-item">
                                    <span class="info-item__point">Дополнительные просмотры видео:</span>
                                    <p class="info-item__number">+3%</p>
                                </div>

                                <div class="case__info-item">
                                    <span class="info-item__point">Стоимость интеграции:</span>
                                    <p class="info-item__number">+3%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactForm></ContactForm>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script>
    import ContactForm from "../components/ContactForm";
    import Header from "../components/TheHeader";
    import Footer from "../components/TheFooter";
    export default {
        name: "BloggersScreen",
        components: {
            ContactForm,
            Header,
            Footer,
        },
        data(){
            return{
                staticDomain: this.$store.state.static.staticDomain,
            }
        },
    }
</script>

<style scoped>

</style>
